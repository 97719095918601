import EstadosBrasileiros from 'src/core/mixin/EstadosBrasileiros'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import vTopFilters from 'src/core/filters/vTopFilters'
import UsuarioModel from 'src/model/usuario/UsuarioModel'
import LogradouroCepModel from 'src/model/cep/LogradouroCepModel.js'
import usuarioLoginStore from 'src/store/usuario-login/'
import { LocalStorage, clone } from 'quasar'
import axios from 'axios'

export default {
  name: 'Perfil',
  mixins: [NotificacaoMixin, EstadosBrasileiros, vTopFilters],
  data () {
    return {
      modelPadrao: new UsuarioModel(),
      modelLogradouroCep: new LogradouroCepModel(),
      logado: LocalStorage.getItem('logado'),
      buscando: true,
      salvando: false,

      optionsGeneros: [
        {
          id: 'Masculino',
          nome: 'Masculino'
        },
        {
          id: 'Feminino',
          nome: 'Feminino'
        }
      ],

      desativarCampos: true,
      forcerRender: 1
    }
  },
  watch: {
    'modelPadrao.form.pessoa_juridica' (agora, antes) {
      if (agora !== antes) {
        this.forcerRender++
      }
    }
  },
  beforeCreate () {
    if (!this.$store.hasModule('usuario-login')) this.$store.registerModule('usuario-login', usuarioLoginStore)
  },
  mounted () {
    if (this.logado) {
      this.getUsuario()
    }
  },
  methods: {
    getUsuario () {
      this.modelPadrao.getId({ id: this.logado.id }).then((res) => {
        if (res && res.dados) this.modelPadrao.form = res.dados
        this.buscando = false
        this.forcerRender++
      }).catch((error) => {
        this.buscando = false
        this.notificacao('erro', error.msg)
        console.error(error)
      })
    },

    validarCep (key) {
      const cep = this.modelPadrao.form[key]
      if (cep !== null && cep.length === 9) {
        this.desativarCampos = true

        this.modelLogradouroCep = new LogradouroCepModel()
        this.modelLogradouroCep.recurso = this.modelLogradouroCep.recurso.replace('{cep}', cep)
        this.modelLogradouroCep.getListagem()
          .then(res => {
            this.desativarCampos = false

            if (res && res.dados) {
              var objCep = res.dados
              this.modelPadrao.form.cep = objCep.cep
              this.modelPadrao.form.logradouro = objCep.logradouro
              this.modelPadrao.form.bairro = objCep.bairro
              this.modelPadrao.form.cidade = objCep.localidade
              this.modelPadrao.form.estado = objCep.uf
              this.modelPadrao.form.complemento = ''
            } else this.notificacao('erro', 'CEP Inválido!')
          })
          .catch(error => {
            this.desativarCampos = false
            this.notificacao('erro', error.msg)
          })
      }
    },

    validarCPF (cpf) {
      cpf = this.$options.filters.soNumeros(cpf)
      return cpf && cpf.length >= 11 ? this.validarCPFCalculo(cpf.toString()) : null
    },

    validarCPFCalculo (strCPF) {
      var Soma = 0
      var Resto = null
      if (strCPF === '00000000000') return false

      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
      Resto = (Soma * 10) % 11

      if ((Resto === 10) || (Resto === 11)) Resto = 0
      if (Resto !== parseInt(strCPF.substring(9, 10))) return false

      Soma = 0
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
      Resto = (Soma * 10) % 11

      if ((Resto === 10) || (Resto === 11)) Resto = 0
      if (Resto !== parseInt(strCPF.substring(10, 11))) return false
      return true
    },

    validarEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },

    validarSenha (senha = '') {
      if (!senha) senha = ''
      return senha.length < 1 || senha.length > 5
    },

    confirmarSenha (confirmarSenha = '') {
      if (!confirmarSenha) confirmarSenha = ''
      if (!this.modelPadrao.form.senha) this.modelPadrao.form.senha = ''
      return confirmarSenha === this.modelPadrao.form.senha
    },

    selecionarFoto () {
      if (this.$refs.inputFoto) {
        this.$refs.inputFoto.click()
      }
    },

    submitFoto (e) {
      const file = e.target.files[0]
      if (file) {
        this.salvando = true
        const not = this.notificacao('aviso', 'Carregando Foto')
        this.modelPadrao.form.foto_url_completa = window.URL.createObjectURL(file)

        const arquivo = new FormData()
        arquivo.append('arquivo', file, file.name)

        const url = process.env.API['usuario'] + '/usuario/upload-arquivos'
        axios
          .post(url, arquivo, null)
          .then(response => {
            this.salvando = false
            not()
            this.modelPadrao.form.foto = response.data.dados.arquivo
            this.modelPadrao.form.foto_url_completa = response.data.dados.arquivo_url_completa
          })
          .catch(error => {
            this.notificacao('erro', error)
            not()
            this.salvando = false
          })
      }
    },

    onSubmit () {
      this.salvando = true
      const aviso = this.notificacao('enviando')
      const send = clone(this.modelPadrao.form)

      delete send.roles
      delete send.senha
      delete send.senha_confirmation

      this.modelPadrao.salvar(send).then((res) => {
        const dados = clone(res.dados)

        if (dados) {
          dados.token = this.logado.token
          LocalStorage.set('logado', dados)
          this.$store.commit('usuario-login/atualizarUsuario', dados)
        }

        aviso()
        this.salvando = false
        this.notificacao('salvo', 'Salvo com sucesso', 3000)
      }).catch(error => {
        aviso()
        this.salvando = false
        this.notificacao('erro', error.msg)
      })
    },

    alterarSenha () {
      this.salvando = true
      const aviso = this.notificacao('enviando')

      const cloneSend = clone(this.modelPadrao.form)
      const send = { id: cloneSend.id, senha: cloneSend.senha, senha_confirmation: cloneSend.senha_confirmation }

      this.modelPadrao.salvar(send).then((res) => {
        const dados = clone(res.dados)

        if (dados) {
          dados.token = this.logado.token
          LocalStorage.set('logado', dados)
          this.$store.commit('usuario-login/atualizarUsuario', dados)
        }

        this.modelPadrao.form.senha = ''
        this.modelPadrao.form.senha_confirmation = ''

        aviso()
        this.salvando = false
        this.notificacao('salvo', 'Senha Salvo com sucesso', 3000)
      }).catch(error => {
        aviso()
        this.salvando = false
        this.notificacao('erro', error.msg)
      })
    }
  }
}
