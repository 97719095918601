import { render, staticRenderFns } from "./Perfil.vue?vue&type=template&id=92c800cc&"
import script from "./Perfil.js?vue&type=script&lang=js&"
export * from "./Perfil.js?vue&type=script&lang=js&"
import style0 from "./Perfil.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QForm,QBtn,QCircularProgress,QAvatar,QToggle,QInput,QSelect,QIcon});
